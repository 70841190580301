import React, { useState, useEffect } from "react";
import { db } from "src/config/configFirebase";
import { collection, getDocs } from "firebase/firestore";
import { getLangFromUrl, useTranslations } from "src/i18n/utils";
import "animate.css"; // Asegúrate de importar animate.css

type Plan = {
  id: string;
  name: {
    [key: string]: string;
  };
  type: string;
  linkMonth?: string;
  linkAnnual?: string;
  linkSemester?: string;
  linkOneTime?: string;
  priceMonthly?: {
    [key: string]: number;
  };
  priceSemester?: {
    [key: string]: number;
  };
  priceAnnual?: {
    [key: string]: number;
  };
  priceOneTime?: {
    [key: string]: number;
  };
  currency: {
    [key: string]: string;
  };
  features: {
    [key: string]: string[];
  };
};

const currencies = [
  { label: "COP", value: "COP" }, // Peso Colombiano
  { label: "USD", value: "USD" },
  { label: "EUR", value: "EUR" },
  { label: "GBP", value: "GBP" },
  { label: "ARS", value: "ARS" }, // Peso Argentino
  { label: "BOB", value: "BOB" }, // Boliviano
  { label: "BRL", value: "BRL" }, // Real Brasileño
  { label: "CLP", value: "CLP" }, // Peso Chileno
  { label: "CRC", value: "CRC" }, // Colón Costarricense
  { label: "CUP", value: "CUP" }, // Peso Cubano
  { label: "DOP", value: "DOP" }, // Peso Dominicano
  { label: "GTQ", value: "GTQ" }, // Quetzal Guatemalteco
  { label: "HNL", value: "HNL" }, // Lempira Hondureño
  { label: "MXN", value: "MXN" }, // Peso Mexicano
  { label: "NIO", value: "NIO" }, // Córdoba Nicaragüense
  { label: "PAB", value: "PAB" }, // Balboa Panameño
  { label: "PYG", value: "PYG" }, // Guaraní Paraguayo
  { label: "PEN", value: "PEN" }, // Sol Peruano
  { label: "UYU", value: "UYU" }, // Peso Uruguayo
  { label: "VES", value: "VES" }, // Bolívar Venezolano
];

const Pricing: React.FC = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [scrollY, setScrollY] = useState(0);
  const [selectedFrequency, setSelectedFrequency] = useState("monthly");
  const [selectedCurrency, setSelectedCurrency] = useState("COP");
  const [exchangeRates, setExchangeRates] = useState<{ [key: string]: number }>(
    {}
  );
  const lang = getLangFromUrl(new URL(window.location.href));
  const t = useTranslations(lang);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    const fetchPlans = async () => {
      const querySnapshot = await getDocs(collection(db, "plans"));
      const fetchedPlans: Plan[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Plan[];
      setPlans(fetchedPlans);
    };

    fetchPlans();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    fetch("https://api.exchangerate-api.com/v4/latest/USD")
      .then((response) => response.json())
      .then((data) => setExchangeRates(data.rates))
      .catch((error) => console.error(error));
  }, []);

  const convertCurrency = (amountInUSD: number): number => {
    if (exchangeRates[selectedCurrency]) {
      const convertedAmount = amountInUSD * exchangeRates[selectedCurrency];
      return Math.round(convertedAmount);
    }
    return Math.round(amountInUSD);
  };

  const formatNumber = (number: number) => {
    return new Intl.NumberFormat(lang, { style: "decimal" }).format(number);
  };

  const renderPlanDetails = (plan: Plan) => {
    let priceUSD;
    if (plan.type === "one-time" && plan.priceOneTime) {
      priceUSD = plan.priceOneTime[lang];
    } else if (selectedFrequency === "annual" && plan.priceAnnual) {
      priceUSD = plan.priceAnnual[lang];
    } else if (selectedFrequency === "semester" && plan.priceSemester) {
      priceUSD = plan.priceSemester[lang];
    } else if (plan.priceMonthly) {
      priceUSD = plan.priceMonthly[lang];
    }

    const convertedPrice = convertCurrency(Number(priceUSD || 0));
    const formattedPrice = formatNumber(convertedPrice);

    return (
      <>
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-300">
            {plan.name[lang]}
          </h3>
          <div className="flex items-center space-x-3">
            <span className="text-2xl font-bold text-gray-800 dark:text-gray-300">
              {plan.type === "free"
                ? ""
                : `${formattedPrice} ${selectedCurrency}`}
            </span>
            {plan.type !== "free" && (
              <select
                value={selectedCurrency}
                onChange={(e) => setSelectedCurrency(e.target.value)}
                className="p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none"
              >
                {currencies.map((currency) => (
                  <option key={currency.value} value={currency.value}>
                    {currency.label}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
        <ul className="list-disc list-inside text-gray-700 dark:text-gray-400 ">
          {plan.features[lang]?.map((feature, index) => (
            <li key={index} className="text-sm font-medium ml-3 my-2">
              {feature}
            </li>
          ))}
        </ul>
      </>
    );
  };

  const renderPlan = (plan: Plan) => {
    const isPro = plan.type.toLowerCase() === "pro";
    const isCurrent = plan.type.toLowerCase();

    return (
      <div
        key={plan.id}
        className={`border rounded-lg p-6 shadow-md bg-white dark:bg-muted-900 animate__animated ${
          scrollY > 2800 ? "animate__fadeInUp" : "animate__fadeOutLeft"
        } ${
          isPro
            ? "md:row-span-2 border-emerald-500 border-2 lg:mr-10"
            : "border-gray-300"
        }`}
      >
        {isPro && (
          <div className="flex justify-center space-x-4 mb-4">
            <button
              className={`px-4 py-2 cursor-pointer font-semibold rounded-lg w-1/3 ${
                selectedFrequency === "monthly"
                  ? "bg-emerald-500 text-white"
                  : "bg-gray-100 text-gray-500 dark:bg-gray-700 dark:text-gray-300"
              }`}
              onClick={() => setSelectedFrequency("monthly")}
            >
              {t("pricingSection.monthly")}
            </button>
            <button
              className={`px-4 py-2 cursor-pointer font-semibold rounded-lg w-1/3 ${
                selectedFrequency === "semester"
                  ? "bg-emerald-500 text-white"
                  : "bg-gray-100 text-gray-500 dark:bg-gray-700 dark:text-gray-300"
              }`}
              onClick={() => setSelectedFrequency("semester")}
            >
              {t("pricingSection.semester")}
            </button>
            <button
              className={`px-4 py-2 cursor-pointer font-semibold rounded-lg w-1/3 ${
                selectedFrequency === "annual"
                  ? "bg-emerald-500 text-white"
                  : "bg-gray-100 text-gray-500 dark:bg-gray-700 dark:text-gray-300"
              }`}
              onClick={() => setSelectedFrequency("annual")}
            >
              {t("pricingSection.annual")}
            </button>
          </div>
        )}
        {renderPlanDetails(plan)}
        {isPro && selectedFrequency === "semester" ? (
          <div className="bg-yellow-100 text-yellow-700 p-2 rounded-lg mt-4 text-center font-semibold">
            {t("pricingSection.semiAnnualSavings")}
            <br />
            {t("pricingSection.noMonthlyPaymentsSemester")}
          </div>
        ) : (
          selectedFrequency === "annual" &&
          isPro && (
            <div className="bg-yellow-100 text-yellow-700 p-2 rounded-lg mt-4 text-center font-semibold">
              {t("pricingSection.annualSavings")}
              <br />
              {t("pricingSection.noMonthlyPaymentsAnnual")}
            </div>
          )
        )}
        {isPro ? (
          <a
            href="/download"
            className="block w-full py-3 text-center text-white bg-emerald-500 rounded-lg font-semibold hover:bg-emerald-600 transition mt-5"
          >
            Comenzar ahora
          </a>
        ) : (
          isCurrent === "free" && (
            <div className="flex items-center justify-center h-2/3">
              <img
                src="/img/screens/plan-free.svg"
                alt="Free Plan Illustration"
                className="h-60 object-contain"
              />
            </div>
          )
        )}
      </div>
    );
  };

  return (
    <div
      className={`grid grid-cols-1 md:grid-cols-2 gap-6 z-50 mt-5 animate__animated ${
        scrollY > 2800 ? "animate__fadeInUp" : "animate__fadeOutLeft"
      }`}
    >
      {plans.map((plan) => renderPlan(plan))}
    </div>
  );
};

export default Pricing;
